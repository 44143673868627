<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <v-card color="grey lighten-5">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="headline">
                Señora Araucanía</v-card-title
              >

              <v-card-subtitle
                >La Señora Araucanía es el Guardián Silencioso de Chile, manifestación de la Madre Divina Cósmica que...
                </v-card-subtitle
              >
              <v-card-actions>
                <v-btn text color="indigo accent-4" @click="irArticulo3">
                  Leer artículo
                </v-btn>
              </v-card-actions>
            </div>

            <v-avatar class="ma-3 ultimos-articulos__imagen" size="100" tile>
              <v-img src="../../../public/angel_guardian_silencioso_de_chile.png"></v-img>
            </v-avatar>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card color="grey lighten-5">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="headline">
                Patriotismo Acuariano</v-card-title
              >

              <v-card-subtitle
                >El patriotismo es el sentimiento que tiene la “Corriente de Vida” por la tierra en la que nació,...
              </v-card-subtitle>
              <v-card-actions>
                <v-btn text color="indigo accent-4" @click="irArticulo2">
                  Leer artículo
                </v-btn>
              </v-card-actions>
            </div>

            <v-avatar class="ma-3 ultimos-articulos__imagen" size="100" tile>
              <v-img src="../../../public/patriotismo_acuariano.jpg"></v-img>
            </v-avatar>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card color="grey lighten-5">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="headline">
                Invocación Patria
              </v-card-title>
              <v-card-subtitle
                >A contar del 1° de septiembre, podemos realizar la “Invocación Patria” tres veces al día; mañana, mediodía...</v-card-subtitle
              >
              <v-card-actions>
                <v-btn text color="indigo accent-4" @click="irArticulo1">
                  Leer artículo
                </v-btn>
              </v-card-actions>
            </div>

            <v-avatar class="ma-3 ultimos-articulos__imagen" size="100" tile>
              <v-img src="../../../public/invocacion_patria.png"></v-img>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card color="indigo lighten-5">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div class="ultimos-articulos__llave-tonal">
              <v-card-title class="headline">
                Llave tonal de la semana</v-card-title
              >

              <v-card-subtitle class="pb-1"
                >Llave Tonal de la Señora Araucanía</v-card-subtitle
              >
              <v-card-text class="pb-0"
                >"Si vas para Chile", de Chito Faró</v-card-text
              >
              <v-card-actions class="ultimos-articulos__audio">
                <audio
                  src="../../assets/audio/Si Vas para Chile.mp3"
                  autoplay
                  controls
                ></audio>
              </v-card-actions>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "UltimosArticulos",
  data() {
    return {
      url: process.env.BASE_URL,
    };
  },
  methods: {
    irArticulo1() {
      this.$router.push("articulos/invocacion_patria");
    },
    irArticulo2() {
      this.$router.push("articulos/patriotismo_acuariano");
    },
    irArticulo3() {
      this.$router.push("articulos/senora_araucania");
    },
  },
};
</script>
<style lang="scss">
@media (max-width: 700px) {
  .ultimos-articulos__imagen {
    display: none;
  }

  .ultimos-articulos__llave-tonal {
    max-width: 100%;
  }

  .ultimos-articulos__audio {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
  }
}
</style>
