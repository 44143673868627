<template>
  <v-card class="mx-auto mt-3" max-width="400">
    <v-img
      class="error--text align-end"
      src="../../../public/senora_araucania.jpg"
    >
    </v-img>
    <v-card-title>Señora Araucanía</v-card-title>
    <v-card-text class="pb-0"> Ángel Guardián Silencioso de Chile</v-card-text>

    <v-divider class="mx-4"></v-divider>
    <v-card-subtitle class="pb-0"> DECRETO DE LA SEMANA </v-card-subtitle>

    <v-card-text class="text--primary" >
      <div class="pt-3">
        “Yo Soy” la Señora Araucanía, Ángel Guardián de Chile que bendice y protege mi ser, este lugar, a todo chileno y toda la nación.
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "SerDeLuz",
};
</script>