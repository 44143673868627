<template>
  <v-container>
    <v-row class="d-flex justify-center">
      <h3 class="mt-5 pt-4">Articulos anteriores</h3>
    </v-row>
    <v-row class="d-flex justify-center mt-1">
      <v-col cols="12" lg="4" class="mt-2" v-for="(card, i) in cards" :key="i">
        <v-card>
          <v-img :src="card.src" height="325px"></v-img>
          <h4 class="ma-4" v-text="card.titulo"></h4>
          <v-card-text class="pt-0" v-text="card.texto"></v-card-text>
          <v-card-actions>
            <v-btn text color="indigo accent-4" :to="card.to"> LEER</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'OtrosArticulos',

  data() {
    return {
      cards: [
      {
          titulo: 'Señora Virgo',
          src: `${process.env.BASE_URL}senora_virgo.jpg`,
          flex: '4',
          texto: 'El 24 de agosto entró la radiación de la Señora Virgo, Directora...',
          to: `articulos/senora_virgo`,
        },
      {
          titulo: 'Padre Hurtado',
          src: `${process.env.BASE_URL}padre_hurtado.png`,
          flex: '4',
          texto: 'Luis Alberto Miguel Hurtado Cruchaga, honrado por la iglesia...',
          to: `articulos/padre_hurtado`,
        },
      {
          titulo: 'María, asunta al cielo ',
          src: `${process.env.BASE_URL}maria_asunta_al_cielo.jpg`,
          flex: '4',
          texto: 'La Madre María, cumplió el proceso de encarnaciones de su...',
          to: `articulos/maria_asunta_al_cielo`,
        },
      ],
    };
  },
};
</script>
